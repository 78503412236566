var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"tree_container",staticClass:"tree_container",on:{"scroll":_vm.handleScroll}},[_c('a-tree',{key:_vm.key,ref:"tree",staticClass:"repositoryTree",attrs:{"replaceFields":_vm.replaceFields,"load-data":_vm.onLoadData,"tree-data":_vm.treeData,"show-line":true,"show-icon":"","selectedKeys":_vm.selectedKeys},on:{"select":_vm.treeSelect,"expand":_vm.onExpand,"rightClick":_vm.rightClick},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var expanded = ref.expanded;
var name = ref.name;
var id = ref.id;
var type = ref.type;
var selected = ref.selected;
var fileType = ref.fileType;
return [_c('div',{staticClass:"title_box"},[(fileType === 'document')?_c('img',{attrs:{"src":_vm.getSrc(selected, type),"alt":"","width":"24"}}):_vm._e(),(fileType === 'document')?_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[(type === 'dir' || type === 'DIR')?_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"5px"},attrs:{"type":expanded ? 'folder-open' : 'folder'}}):_c('a-icon',{staticClass:"tree_icon",staticStyle:{"margin-left":"10px"},attrs:{"type":_vm.getIconType(name,type)}}),_c('span',{staticClass:"tree_title"},[_vm._v(" "+_vm._s(name)+" ")])],1)])]}}])},[_c('a-icon',{attrs:{"slot":"switcherIcon","type":"down"},slot:"switcherIcon"}),_c('a-icon',{attrs:{"slot":"switcherIcon","type":"folder-open"},slot:"switcherIcon"})],1)],1),_c('div',[(_vm.loadingMore)?_c('a-spin',{staticStyle:{"width":"100%"}}):_vm._e()],1),_c('rightMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContextMenu),expression:"showContextMenu"}],ref:"rightMenu",style:(_vm.contextMenuStyle),attrs:{"folibRepository":_vm.folibRepository,"repositoryType":_vm.repositoryType,"currentFileDetial":_vm.currentFileDetial,"uploadEnabled":_vm.uploadEnabled,"copyEnabled":_vm.copyEnabled,"dispatchEnabled":_vm.dispatchEnabled,"moveEnabled":_vm.moveEnabled,"currentTreeNode":_vm.currentTreeNode,"isTrashView":_vm.isTrashView},on:{"reload":_vm.reload,"handleMenuClick":_vm.handleMenuClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }